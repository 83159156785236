import consumer from "./consumer"

consumer.subscriptions.create({
  channel: "MessagesChannel",
  patient_id: window.location.pathname.match(/chats\/(.*)\/*/) && window.location.pathname.match(/chats\/(.*)\/*/)[1]
}, {
  connected() {
    console.log('Connected to messages')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    Spruce.store('chat').messages.push(data.message)
    let element = document.getElementById("messages-container");
    element.scrollTop = element.scrollHeight;
    // Called when there's incoming data on the websocket for this channel
  }
});
